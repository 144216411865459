<template>
  <b-container>
    <b-row>
      <b-col md="3">
        <template v-if="$options.components['bill-payment-category']">
          <bill-payment-category />
        </template>
        <!-- <view-plans @toAmount="callback" /> -->
      </b-col>
      <b-col md="9">
        <b-row>
          <b-col md="7">
            <div
              data-app
              class="mt-4 text-center promotional_akshay_tab_div billpayment-tabsec orange-tab m-4"
            >
              <b-tabs pills v-model="tabIndex">
                <b-tab title="Prepaid" active>
                  <b-card-text>
                    <form>
                      <div>
                        <v-text-field
                          label="Enter Mobile Number"
                          v-model="mobileNumber"
                          v-on:keyup="validateMobile(mobileNumber)"
                        ></v-text-field>
                        <p
                          v-if="isMobileValid === false"
                          class="is-valid-phone"
                        >
                          Enter a valid phone number
                        </p>
                      </div>
                      <v-text-field
                        label="Operator "
                        v-model="operator"
                        readonly
                      ></v-text-field>

                      <v-text-field
                        label="Circle"
                        v-model="circle"
                        readonly
                      ></v-text-field>
                      <b-row>
                        <b-col md="6">
                          <div class="viewplan-input-div">
                            <button type="button" @click="ViewPlanApi">
                              <h5 class="viewplan-text">Select-Plans</h5>
                            </button>
                          </div>
                        </b-col>
                        <b-col md="6">
                          <v-text-field
                            label=" Amount"
                            placeholder="₹"
                            v-model="amount"
                            readonly
                          ></v-text-field>
                        </b-col>
                      </b-row>

                      <div class="buttondv mt-4">
                        <button
                          @click="clearField"
                          type="button"
                          class="btn btn-outline-primary mb-2 mr-2"
                        >
                          Clear
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary mb-2 ml-2"
                          :disabled="rechargeButton"
                          @click="gotocheckout"
                        >
                          Recharge
                        </button>
                      </div>
                    </form>
                  </b-card-text>
                </b-tab>
                <b-tab title="Postpaid">
                  <b-card-text>
                    <form class="my-4">
                      <div>
                        <v-text-field
                          label="Enter Mobile Number"
                          v-model="postpaidNumber"
                          v-on:keyup="
                            () => {
                              validateMobile(postpaidNumber, type.post);
                            }
                          "
                        >
                        </v-text-field>
                        <p
                          v-if="isMobileValid === false"
                          class="is-valid-phone"
                        >
                          Enter a valid phone number
                        </p>
                      </div>

                      <!-- <v-select
                        class="text-left"
                        v-model="operator"
                        label="Select Operator"
                        :items="items"
                      >
                      </v-select>-->
                      <v-text-field
                        label="Operator"
                        v-model="postpaidOperator"
                        readonly
                      ></v-text-field>
                      <div class="buttondv mt-4 mb-4">
                        <button
                          @click="clearField"
                          type="button"
                          class="btn btn-outline-primary mb-2 mr-2"
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary mb-2 ml-2"
                          @click="postViewBill"
                        >
                          Fetch Bill
                        </button>
                      </div>
                    </form>
                    <div class="bill-fetch-detail my-4" v-if="showPostpaid">
                      <ul class="list-unstyled od-ulli text-left">
                        <b-media tag="li">
                          <template #aside>Customer Name:</template>
                          <p class="mb-0 font-weight-bold">
                            {{ viewBill[0].userName }}
                          </p>
                        </b-media>
                        <b-media tag="li">
                          <template #aside>Bill Date:</template>
                          <p class="mb-0 font-weight-bold">
                            {{ viewBill[0].billDate }}
                          </p>
                        </b-media>
                        <b-media tag="li">
                          <template #aside>Due Date:</template>
                          <p class="mb-0 font-weight-bold">
                            {{ viewBill[0].dueDate }}
                          </p>
                        </b-media>
                      </ul>
                      <div class="d-flex justify-content-between my-4">
                        <h5>Bill Amount:</h5>
                        <h5>&#x20B9; {{ viewBill[0].billAmount }}</h5>
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary w-100"
                        :disabled="viewBill[0].billAmount <= 0"
                        @click="postCheckout"
                      >
                        Continue
                      </button>
                    </div>
                  </b-card-text>
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
          <!-- <b-col md="5 mb-4">
            <div>
              <h5 class="mb-4">Upcoming Action</h5>
              <template
                v-if="$options.components['bill-payment-action-transaction']"
              >
                <bill-payment-action-transaction />
              </template>
            </div>
            <div>
              <h5 class="mt-4 mb-4">Recent Transaction</h5>
              <template
                v-if="$options.components['bill-payment-action-transaction']"
              >
                <bill-payment-action-transaction />
              </template>
            </div>
          </b-col>
          <b-col md="12" class="mt-4 latest-offer-div">
            <h4 class="mb-4 mt-3">Latest Offers</h4>
            <template v-if="$options.components['bill-payment-latest-offers']">
              <bill-payment-latest-offers />
            </template>
          </b-col>-->
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
/*eslint-disable*/
import { mapState } from "vuex";
export default {
  name: "mobile_recharge",
  data() {
    return {
      // items: ["Airtel", "Jio", "Vodafone", "idea"],
      //states: ["Kerala", "Hyderabad", "West Bengal", "Uttar Pradesh"],
      mobileNumber: null,
      operator: "",
      circle: "",
      operatorId: null,
      circleId: null,
      phone: null,
      amount: null,
      postpaidNumber: null,
      postpaidOperator: null,
      showPostpaid: false,
      selectedPlan: [],
      isMobileValid: "",
      type: {
        post: "post",
        pre: "pre",
      },
    };
  },

  computed: {
    ...mapState({
      categoryName: (state) => state.home.marketList,
      categoryList: (state) => state.billPayment.categoryList,
      recomendedData: (state) => state.billPayment.recomendedData,
      availablePlans: (state) => state.billPayment.availablePlans,
      productList: (state) => state.billPayment.productList,
      viewBill: (state) => state.billPayment.postpaidBill,
      postpaidId: (state) => state.billPayment.postpaidId,
      productTypeData: (state) => state.billPayment.productTypeData,
      productData: (state) => state.billPayment.productData,
      userInfo: (state) => state.auth.userInfo,
      finalCheckoutData: (state) => state.billPayment.finalCheckoutData,
    }),
    isMobileValid() {
      var data;
      if (this.mobileNumber.length > 10) data = false;
      var isPhone = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/ || /^\d{10}$/;
      if (isPhone.test(this.mobileNumber)) {
        data = true;
      } else {
        data = false;
      }
      console.clear();
      console.log(data);
    },
    rechargeButton() {
      if (
        this.operator == "" ||
        this.circle == "" ||
        this.amount == null ||
        this.mobileNumber == null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async validateMobile(mobile, type) {
      if (mobile.length === 10) {
        this.isMobileValid = true;
        if (type === "post") {
          // Post paid
          await this.postpaidData();
        } else {
          // Prepaid
          await this.getData();
        }
      }
      if (mobile.length > 10 || mobile.length < 10) {
        this.isMobileValid = false;
      }
    },
    clearField() {
      this.mobileNumber = "";
      this.operator = "";
      this.circle = "";
      this.amount = "";
      this.postpaidNumber = "";
      this.postpaidOperator = "";
      this.showPostpaid = false;
    },

    async gotocheckout() {
      this.$router.push({
        name: "mobile-prepaid",
        params: {
          operator: this.operator || "",
          circle: this.circle || "",
          mobileNumber: this.mobileNumber,
          amount: this.amount || "",
          plan: this.selectedPlan?.planName || "",
          validity: this.selectedPlan?.validity || "",
          planCode: this.selectedPlan?.planCode || "",
        },
      });
      const selectedCategoryId = "62d5007b9c487bede6ee8f09";

      await this.$store.dispatch(
        "billPayment/fetchProductTypeData",
        selectedCategoryId
      );

      let selectedProductType = this.productTypeData.filter(
        (e) => e.name === "Prepaid"
      );

      const payloadData = {
        categoryId: selectedProductType[0]._id,
        offset: 0,
        itemType: "SERVICE",
      };
      await this.$store.dispatch("billPayment/fetchProductData", payloadData);

      const payload = {
        item: this.productData[0]._id,
        checkout: {
          type: "CASHFREE",
          customerNo: this.userInfo?.customerNo || "",
          userEmail: this.userInfo?.authentication?.email || "",
          userPhone: this.userInfo?.authentication?.phone || "",
        },

        custom: {
          MobileNumber: this.$route.params.mobileNumber,
          PlanCode: this.$route.params.planCode,
          Amount: this.$route.params.amount,
          OperatorId:
            this.recomendedData?.operatorInfo[0]?.operatorid.toString() || "",
          CircleId: this.recomendedData?.circleInfo[0]?.circleid || "",
          AdParams: {},
          market: {
            name: "Bill Payment",
            serviceProvider: "MOBIKWIK",
          },
          ServiceCategory: "Prepaid",
          OperatorName: this.operator,
          isMobile: false,
        },
      };
      await this.$store.dispatch("billPayment/getCheckoutDataMobile", payload);
      let payloadbenifits = {
        orderAmount: this.finalCheckoutData?.data?.items[0]?.price || "",
        provider: this.finalCheckoutData?.data?.items[0]?.provider || "",
      };

      await this.$store.dispatch(
        "billPayment/transactionBenefits",
        payloadbenifits
      );
    },
    async postCheckout() {
      this.$router.push({
        name: "checkout-postpaid",
        params: {
          customerNumber: this.postpaidNumber || "",
          operator: this.postpaidOperator || "",
          billDate: this.viewBill[0]?.billDate || "",
          dueDate: this.viewBill[0]?.dueDate || "",
          postAmount: this.viewBill[0]?.billAmount || "",
          customerName: this.viewBill[0]?.userName || "",
        },
      });
      const selectedCategoryId = "62d5007b9c487bede6ee8f09";

      await this.$store.dispatch(
        "billPayment/fetchProductTypeData",
        selectedCategoryId
      );

      let selectedProductType = this.productTypeData?.filter(
        (e) => e.name === "Postpaid"
      );

      const payloadData = {
        categoryId: selectedProductType[0]._id,
        offset: 0,
        itemType: "SERVICE",
      };
      await this.$store.dispatch("billPayment/fetchProductData", payloadData);
      const payload = {
        item: this.productData[0]._id,
        checkout: {
          type: "CASHFREE",
          customerNo: this.userInfo?.customerNo || "",
          userEmail: this.userInfo?.authentication?.email || "",
          userPhone: this.userInfo?.authentication?.phone || "",
        },
        custom: {
          MobileNumber: this.$route.params.customerNumber || "",
          PlanCode: "",
          Amount: this.$route.params.postAmount || "",
          OperatorId: this.postpaidId?.operatorid.toString(),
          CircleId: this.recomendedData?.circleInfo[0]?.circleid.toString(),
          AdParams: {},
          market: {
            name: "Bill Payment",
            serviceProvider: "MOBIKWIK",
          },
          ServiceCategory: "Postpaid",
          OperatorName:
            this.recomendedData?.operatorInfo[0]?.operatorname || "",
          isMobile: false,
        },
      };
      await this.$store.dispatch("billPayment/getCheckoutDataMobile", payload);
      let payloadbenifits = {
        orderAmount: this.finalCheckoutData?.data?.items[0]?.price || "",
        provider: this.finalCheckoutData?.data?.items[0]?.provider || "",
      };

      await this.$store.dispatch(
        "billPayment/transactionBenefits",
        payloadbenifits
      );
    },
    async ViewPlanApi() {
      let payload1 = {
        operatorId: this.recomendedData?.operatorInfo[0]?.operatorid || "",
        circleId: this.recomendedData?.circleInfo[0]?.circleid || "",
        phone: this.mobileNumber || "",
      };
      await this.$store.dispatch("billPayment/getallPlans", payload1);
      this.$router.push({
        name: `view-plans`,
        params: {
          circle: this.circle || "",
          operator: this.operator || "",
          mobileNumber: this.mobileNumber || "",
        },
      });
    },
    async postViewBill() {
      let payload = {
        adParams: {},
        cir: this.recomendedData?.circleInfo[0]?.circleid || "",
        cn: this.postpaidNumber || "",
        op: this.postpaidId.operatorid || "",
        // adParams: {},
        // cir: "12",
        // cn: "8336907193",
        // op: "436",
      };
      await this.$store.dispatch("billPayment/postPaidViewBill", payload);
      this.showPostpaid = true;
    },

    async getData() {
      await this.$store.dispatch("billPayment/getMobile", this.mobileNumber);
      let payload1 = {
        operatorId: this.recomendedData?.operatorInfo[0]?.operatorid || "",
        circleId: this.recomendedData?.circleInfo[0]?.circleid || "",
        phone: this.mobileNumber || "",
      };

      this.operator = this.recomendedData?.operatorInfo[0]?.operatorname || "";
      this.circle = this.recomendedData?.circleInfo[0]?.circlename || "";
      await this.$store.dispatch("billPayment/getallPlans", payload1);
    },

    async getpostPaidId() {
      let payloadData = {
        category: "Postpaid",
        name: this.recomendedData?.operatorInfo[0]?.operatorname || "",
      };
      this.$store.dispatch("billPayment/getPostpaidOperatorid", payloadData);
    },
    async postpaidData() {
      await this.$store.dispatch("billPayment/getMobile", this.postpaidNumber);
      await this.getpostPaidId();
      this.postpaidOperator =
        this.recomendedData?.operatorInfo[0]?.operatorname || "";
    },
  },
  async mounted() {
    await this.$store.dispatch(
      "billPayment/getCategory",
      "62d4fef39c487b63efee8f03"
    );
    this.amount = this.$route.params.amount;
    this.mobileNumber = this.$route.params.mobileNumber;
    this.circle = this.$route.params.circle;
    this.operator = this.$route.params.operator;
    let combinedPlans = [].concat(
      this.availablePlans?.allRechargePlans || "",
      this.recomendedData?.recomendedPlans || ""
    );
    this.selectedPlan = combinedPlans.find(
      (plan) => plan.amount == this.amount
    );
  },
};
</script>
<style scoped>
.promotional_akshay_tab_div .nav {
  display: inline-flex;
}
.billpayment-tabsec .v-list {
  text-align: left;
}
.viewplan-input-div {
  position: relative;
}
.viewplan-text {
  z-index: 2;
  margin-top: 20px;
  margin-right: 80px;
  text-decoration: underline;
  color: #ff6a2e;
}
.buttondv .btn-outline-primary {
  width: 120px;
}
.buttondv .btn-primary {
  width: 120px;
}
.latest-offer-div {
  background-color: #f8f8f8;
}
.is-valid-phone {
  font-size: 14px;
  margin-top: 0;
  margin-left: 0;
  color: red;
}
.od-ulli .media-aside {
  width: 150px;
}
.od-ulli li {
  margin-bottom: 6px;
  margin-top: 6px;
}
.bill-fetch-detail {
  padding-top: 20px;
}
</style>
